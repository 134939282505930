<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialog"
      persistent
      scrollable
      content-class="common-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          v-if="$slots.title"
          class="headline dialog-custom-header text-uppercase custom-border-bottom custom-header-blue-text white-background"
        >
          <slot name="title"></slot>
        </v-card-title>
        <v-card-text
          v-if="$slots.body"
          :class="{ 'px-8 py-4': !dense, 'px-0 py-0': dense }"
        >
          <slot name="body"></slot>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions v-if="$slots.action">
          <v-spacer></v-spacer>
          <slot name="action"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
    },
    dialogWidth: {
      type: Number,
      default: 768,
    },
  },
};
</script>
