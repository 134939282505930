<template>
  <Dialog :dialog="dialog" :dialog-width="600">
    <template v-slot:title>Update Event</template>
    <template v-slot:body>
      <v-form ref="addCreateEventForm" v-model.trim="formValid" lazy-validation>
        <v-row class="mb-4">
          <v-col md="12">
            <label for="event-name" class="btx-label mt-2">Event Name</label>
            <TextInput
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              id="event-name"
              placeholder="Event Name"
              v-model="event.name"
              class="mt-0"
            ></TextInput>
          </v-col>
          <v-col md="12">
            <label for="description" class="btx-label mt-2 required"
              >Description</label
            >
            <TextAreaInput
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              id="description"
              placeholder="description"
              v-model="event.description"
              class="mt-0"
            ></TextAreaInput>
          </v-col>
          <v-col sm="6">
            <label for="start-date" class="btx-label mt-2">Start Date</label>
            <TextInput
              type="date"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              :id="`start-date`"
              placeholder="Start Date"
              v-model="event.start_date"
              class="mt-0"
            ></TextInput>
          </v-col>
          <v-col sm="6">
            <label for="start-time" class="btx-label mt-2">Start Time</label>
            <TextInput
              type="time"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              :id="`start-time`"
              placeholder="Start time"
              v-model="event.start_time"
              class="mt-0"
            ></TextInput>
          </v-col>
          <v-col sm="6">
            <label for="end-date" class="btx-label mt-2">End Date</label>
            <TextInput
              type="date"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              :id="`end-date`"
              placeholder="End Date"
              v-model="event.end_date"
              class="mt-0"
            ></TextInput>
          </v-col>
          <v-col sm="6">
            <label for="end-time" class="btx-label mt-2">End Time</label>
            <TextInput
              type="time"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              :id="`end-time`"
              placeholder="End time"
              v-model="event.end_time"
              class="mt-0"
            ></TextInput>
          </v-col>
          <v-col md="12">
            <label :for="`participant`" class="btx-label mt-2"
              >Participant</label
            >
            <AutoCompleteInput
              hide-details
              :items="participantList"
              :disabled="pageLoading"
              :loading="pageLoading"
              :id="`participant`"
              placeholder="Participant"
              multiple
              v-model="event.participant"
              class="mt-0"
            ></AutoCompleteInput>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <v-btn depressed tile v-on:click="$emit('close', false)"> Close </v-btn>
      <v-btn class="white--text" depressed color="blue darken-4" tile>
        Save
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
export default {
  name: "createEvents",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formValid: true,
      pageLoading: false,
      participantList: ["John", "Peter"],
      event: {
        name: null,
        description: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        participant: [],
      },
    };
  },
  components: {
    Dialog,
    TextAreaInput,
    AutoCompleteInput,
    TextInput,
  },
};
</script>
